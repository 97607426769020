<template>
  <div>
<!--    <v-json-viewer-->
<!--      :value="value"-->
<!--      :expanded="expanded"-->
<!--      :expand-depth="expandDepth"-->
<!--      theme="custom-json-style"-->
<!--    />-->
    <v-json-viewer
      :value="value"
      :expanded="expanded"
      :expand-depth="expandDepth"
    />
  </div>
</template>

<script>
import VJsonViewer from 'vue-json-viewer';

export default {
  name: 'JsonViewer',
  props: {
    value: Object,
    expanded: { type: Boolean, default: false },
    expandDepth: { type: Number, default: 1 },
  },
  components: { VJsonViewer },
};
</script>

<style lang="scss">
.custom-json-style {
  box-sizing: border-box;
  position: relative;

  &.boxed {
    border: 1px solid #eee;
    border-radius: 6px;

    &:hover {
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
      border-color: transparent;
      position: relative;
    }
  }

  &.jv-light {
    background: #fff;
    white-space: nowrap;
    color: #525252;
    font-size: 14px;
    font-family: Consolas, Menlo, Courier, monospace;

    .jv-ellipsis {
      color: #999;
      background-color: #eee;
      display: inline-block;
      line-height: 0.9;
      font-size: 0.9em;
      padding: 0px 4px 2px 4px;
      margin: 0 4px;
      border-radius: 3px;
      vertical-align: 2px;
      cursor: pointer;
      user-select: none;
    }

    .jv-button {
      color: #49b3ff;
    }

    .jv-key {
      color: #111111;
      margin-right: 4px;
    }

    .jv-item {
      &.jv-array {
        color: #111111;
      }

      &.jv-boolean {
        color: #fc1e70;
      }

      &.jv-function {
        color: #067bca;
      }

      &.jv-number {
        color: #fc1e70;
      }

      &.jv-object {
        color: #111111;
      }

      &.jv-undefined {
        color: #e08331;
      }

      &.jv-string {
        color: #42b983;
        word-break: break-word;
        white-space: normal;

        .jv-link {
          color: #0366d6;
        }
      }
    }

    .jv-code {
      .jv-toggle {
        &:before {
          padding: 0px 2px;
          border-radius: 2px;
        }

        &:hover {
          &:before {
            background: #eee;
          }
        }
      }
    }
  }

  .jv-code {
    overflow: hidden;
    padding: 10px;

    &.boxed {
      max-height: 300px;
    }

    &.open {
      max-height: initial !important;
      overflow: visible;
      overflow-x: auto;
      padding-bottom: 45px;
    }
  }

  .jv-toggle {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-right: 2px;
    display: inline-block;
    transition: transform 0.1s;

    &.open {
      transform: rotate(90deg);
    }
  }

  .jv-more {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    width: 100%;
    text-align: center;
    cursor: pointer;

    .jv-toggle {
      position: relative;
      top: 40%;
      z-index: 2;
      color: #888;
      transition: all 0.1s;
      transform: rotate(90deg);

      &.open {
        transform: rotate(-90deg);
      }
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 20%,
        rgba(230, 230, 230, 0.3) 100%
      );
      transition: all 0.1s;
    }

    &:hover {
      .jv-toggle {
        top: 50%;
        color: #111;
      }

      &:after {
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 20%,
          rgba(230, 230, 230, 0.3) 100%
        );
      }
    }
  }

  .jv-button {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    z-index: 5;

    &.copied {
      opacity: 0.4;
      cursor: default;
    }
  }

  .jv-tooltip {
    position: absolute;

    &.right {
      right: 15px;
    }

    &.left {
      left: 15px;
    }
  }

  .j-icon {
    font-size: 12px;
  }

  .jv-node {
    position: relative;

    &:after {
      content: ',';
    }

    &:last-of-type {
      &:after {
        content: '';
      }
    }

    &.toggle {
      margin-left: 13px !important;
    }

    & .jv-node {
      margin-left: 25px;
    }
  }
}
</style>